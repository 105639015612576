<template>
    <div>
        <van-sticky>
            <title-bar2 title="调剂专业详情" />
        </van-sticky>
        <div class="major-box">
            <div class="major-content">
                <h3 class="major-title1">{{ adjustMajorName }}</h3>
                <div>
                    <span class="major-title2">专业代码</span>
                    <span class="major-text">{{ adjustMajorCode }}</span>
                </div>
                <div>
                    <span class="major-title2">所属省份</span>
                    <span class="major-text">{{ adjustUniversityProvince }}</span>
                    <span class="verticalbar"></span>
                    <span class="major-text">{{ adjustUniversityPartition }}区</span>
                </div>
                <div>
                    <span class="major-title2">所属院校</span>
                    <span class="major-text">{{ adjustUniversityName }}</span>
                </div>
                <div>
                    <span class="major-title2">招生年份</span>
                    <span class="major-text">{{ adjustYear }}</span>
                </div>
                <div>
                    <span class="major-title2">所属学院</span>
                    <span class="major-text">{{ adjustCollegeName }}</span>
                </div>
                <div>
                    <span class="major-title2">学习方式</span>
                    <span class="major-text">{{ adjustMajorLearningWay }}</span>
                </div>
                <div>
                    <span class="major-title2">学位类别</span>
                    <span class="major-text">{{ adjustMajorDegreeType }}</span>
                </div>
                <div>
                    <span class="major-title2">招生调剂人数</span>
                    <span class="major-text">{{ adjustPeopleNumber }}</span>
                </div>
            </div>
            <div class="major-content">
                <h3 class="major-title1">考试科目</h3>
                <div>
                    <div class="major-title2">研究方向</div>
                    <div class="major-text">{{ majorResearchDirection }}</div>
                </div>
                <div>
                    <div class="major-title2">初试科目</div>
                    <div class="major-text">{{ majorFirstSubject }}</div>
                </div>
                <div>
                    <div class="major-title2">复试科目</div>
                    <div class="major-text">{{ majorSecondSubject }}</div>
                </div>
            </div>
            <div class="major-content">
                <h3 class="major-title1">调剂详情</h3>
                <div>
                    <div class="major-title2">研究方向</div>
                    <div class="major-text">{{ adjustMajorResearchDirection }}</div>
                </div>
                <div>
                    <div class="major-title2">说 明</div>
                    <div class="major-text" v-html="adjustDescriptionHtml"></div>
                </div>
            </div>
            <div class="major-content">
                <h3 class="major-title1">复试资料</h3>
                <div class="major-text" v-if="documentInfos.length == 0">该科目资料暂未上传，请联系商家上架！</div>
                <div v-for="(docuemntInfo, index) in documentInfos">
                    <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                    <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                    <div class="major-line" v-if="index < documentInfos.length - 1"></div>
                </div>
            </div>
            <div class="major-content">
                <h3 class="major-title1">报录数据</h3>
                <div class="major-text" v-if="admitInfos.length == 0">数据统计中，请及时关注本站。</div>
                <div v-for="(admitInfo, index) in admitInfos">
                    <div class="major-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</div>
                    <div class="major-price">数据会员</div>
                    <div class="major-line" v-if="index < admitInfos.length - 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            year: null,
            universityCode: null,
            collegeCode: null,
            majorCode: null,
            majorLearnway: null,
            majorResearchDirection: null,
            majorFirstSubject: null,
            majorSecondSubject: null,
            adjustGuid: null,
            adjustYear: null,
            adjustUniversityCode: null,
            adjustUniversityName: null,
            adjustUniversityProvince: null,
            adjustUniversityPartition: null,
            adjustCollegeCode: null,
            adjustCollegeName: null,
            adjustMajorCode: null,
            adjustMajorName: null,
            adjustMajorLearningWay: null,
            adjustMajorDegreeType: null,
            adjustMajorResearchDirection: null,
            adjustPeopleNumber: null,
            adjustDescription: null,
            documentInfos: [],
            admitInfos: [],
        };
    },
    computed: {
        schoolImg() {
            if (this.universityCode) {
                return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
            }
        },
        adjustDescriptionHtml() {
            return Tools.textToHtml(this.adjustDescription);
        }
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.year = this.$route.params.year;
            this.universityCode = this.$route.params.universityCode;
            this.collegeCode = this.$route.params.collegeCode;
            this.majorCode = this.$route.params.majorCode;
            this.majorLearnway = this.$route.params.majorLearnway;
        },
        //获取专业信息
        getMajorInfo() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
                MajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.adjust.majorInfo, model)
                .then((res) => {
                    this.majorResearchDirection = res.data.Data.MajorResearchDirection;
                    this.majorFirstSubject = res.data.Data.MajorFirstSubject;
                    this.majorSecondSubject = res.data.Data.MajorSecondSubject;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取调剂信息
        getAdjustInfo() {
            this.$toast.loading({ message: "正在加载...", duration: 0, forbidClick: true });
            var model = {
                adjustYear: this.year,
                adjustUniversityCode: this.universityCode,
                adjustCollegeCode: this.collegeCode,
                adjustMajorCode: this.majorCode,
                adjustMajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.adjust.info, model)
                .then((res) => {
                    this.adjustYear = res.data.Data.AdjustYear;
                    this.adjustUniversityCode = res.data.Data.AdjustUniversityCode;
                    this.adjustUniversityName = res.data.Data.AdjustUniversityName;
                    this.adjustUniversityProvince = res.data.Data.AdjustUniversityProvince;
                    this.adjustUniversityPartition = res.data.Data.AdjustUniversityPartition;
                    this.adjustCollegeCode = res.data.Data.AdjustCollegeCode;
                    this.adjustCollegeName = res.data.Data.AdjustCollegeName;
                    this.adjustMajorCode = res.data.Data.AdjustMajorCode;
                    this.adjustMajorName = res.data.Data.AdjustMajorName;
                    this.adjustMajorLearningWay = res.data.Data.AdjustMajorLearningWay;
                    this.adjustMajorDegreeType = res.data.Data.AdjustMajorDegreeType;
                    this.adjustMajorResearchDirection = res.data.Data.AdjustMajorResearchDirection;
                    this.adjustPeopleNumber = res.data.Data.AdjustPeopleNumber;
                    this.adjustDescription = res.data.Data.AdjustDescription;
                    this.addVisitProductRecord();
                    this.addVisitSchoolRecord();
                    this.$toast.clear();
                })
                .catch((error) => {
                    this.$toast.clear();
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取关联文档列表
        getDocumentInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            return this.$axios
                .post(Config.adjust.related.secondDocumentList, model)
                .then((res) => {
                    this.documentInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取关联报录列表
        getAdmitInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            return this.$axios
                .post(Config.adjust.related.admitList, model)
                .then((res) => {
                    this.admitInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //添加访问商品记录
        addVisitProductRecord() {
            if (this.adjustUniversityName && this.adjustCollegeName && this.adjustMajorName) {
                Manager.addVisitProductRecord(`调剂公告 ${this.adjustUniversityName}-${this.adjustCollegeName}-${this.adjustMajorName}`);
            }
        },
        //添加访问学校记录
        addVisitSchoolRecord() {
            if (this.adjustUniversityCode && this.adjustCollegeCode && this.adjustMajorCode) {
                Manager.addVisitSchoolRecord({
                    UniversityCode: this.adjustUniversityCode,
                    CollegeCode: this.adjustCollegeCode,
                    MajorCode: this.adjustMajorCode,
                    RecordModule: 8,
                });
            }
        },
        //点击资料
        onDocumentClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            })
        },
        //点击报录数据
        onAdmitClick(admitInfo) {
            this.$router.push({
                name: "Admit1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: this.universityCode,
                    collegeCode: this.adjustCollegeCode,
                    majorCode: this.adjustMajorCode,
                    majorLearnway: this.adjustMajorLearningWay,
                }
            })
        },
    },
    mounted() {
        this.getRoutParams();
        this.getMajorInfo();
        this.getAdjustInfo();
        this.getDocumentInfos();
        this.getAdmitInfos();
    }
};
</script>

<style scoped>
.major-box {
    padding: 10px;
}

.major-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 0 10px;
    margin: 5px 0;
}

.major-title1 {
    font-size: 18px;
    font-weight: 500;
}

.major-title2 {
    display: inline-block;
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    word-spacing: 24px;
}

.major-text {
    line-height: 26px;
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.major-name {
    color: gray;
    font-size: 14px;
    margin-bottom: 5px;
}

.major-name:hover {
    color: #FE5E03;
}

.major-price {
    width: 100%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: gray;
    font-size: 14px;
}

.major-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0px;
}

.verticalbar {
    border-left: 1px solid #c5c5c5;
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 10px;
}
</style>