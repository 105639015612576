<template>
  <div>
    <div>
      <school-banner :backgroundImg="proxyBannerImg" :schoolImg="schoolImg" :contactImg="proxyContactImg" :name="universityName" :province="universityProvince" :characteristic="universityCharacteristic" :website="universityWebsite" :phone="universityPhone" :email="universityEmail"></school-banner>
    </div>
    <div class="content-box" v-loading="loading" element-loading-text="加载中...">
      <div class="content-left">
        <div class="content-title">{{ adjustMajorName }}</div>
        <header-entry>专业信息</header-entry>
        <div class="content-border content-major">
          <div class="content-major-item">
            <span class="content-major-item-title">所属学校：</span>
            <span class="content-major-item-text">{{ universityName }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">招生年份：</span>
            <span class="content-major-item-text">{{ adjustYear }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">所属学院：</span>
            <span class="content-major-item-text">{{ adjustCollegeName }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">专业代码：</span>
            <span class="content-major-item-text">{{ adjustMajorCode }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">所属省份：</span>
            <span class="content-major-item-text">{{ adjustUniversityProvince + ' ' + adjustUniversityPartition }}区</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">学位类别：</span>
            <span class="content-major-item-text">{{ adjustMajorDegreeType }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">学习方式：</span>
            <span class="content-major-item-text">{{ adjustMajorLearningWay }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">调剂名额：</span>
            <span class="content-major-item-text">{{ adjustPeopleNumber }}</span>
          </div>
        </div>
        <header-entry>考试科目</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item">
            <span class="content-subject-item-title">研究方向</span>
            <span class="content-subject-item-text">{{ majorResearchDirection }}</span>
          </div>
          <div class="content-subject-item">
            <span class="content-subject-item-title">初试科目</span>
            <span class="content-subject-item-text">{{ majorFirstSubject }}</span>
          </div>
          <div class="content-subject-item">
            <span class="content-subject-item-title">复试科目</span>
            <span class="content-subject-item-text">{{ majorSecondSubject }}</span>
          </div>
          <div class="content-exemption">说明：根据《2024年全国硕士研究生招生工作管理规定》，初试科目与调入专业初试科目相同或相近，其中初试全国统一命题科目应与调入专业全国统一命题科目相同。</div>
        </div>
        <header-entry>调剂详情</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item" v-if="adjustMajorResearchDirection">
            <span class="content-subject-item-title">研究方向</span>
            <span class="content-subject-item-text">{{ adjustMajorResearchDirection }}</span>
          </div>
          <div class="content-subject-item">
            <span class="content-subject-item-title">说 明</span>
            <span class="content-subject-item-text" v-html="adjustDescriptionHtml"></span>
          </div>
          <div class="content-exemption">免责声明：请与招生院校再次确认，最终以实际录取情况为准。</div>
        </div>
        <header-entry>复试资料</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item" v-if="documentInfos.length == 0">该科目资料暂未上传，请联系商家上架！</div>
          <template v-for="docuemntInfo in documentInfos">
            <div class="content-subject-item">
              <span class="content-subject-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
              <span class="content-subject-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
            </div>
          </template>
        </div>
        <header-entry>报录数据</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item" v-if="admitInfos.length == 0">数据统计中，请及时关注本站。</div>
          <template v-for="admitInfo in admitInfos">
            <div class="content-subject-item">
              <span class="content-subject-item-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</span>
              <span class="content-subject-item-price">数据会员</span>
            </div>
          </template>
        </div>
      </div>
      <div class="content-right">
        <school-hot></school-hot>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolBanner from "@/components/PC/SchoolBanner";
import SchoolHot from "@/components/PC/SchoolHot";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
  name: "PCModule",
  components: {
    HeaderEntry,
    SchoolBanner,
    SchoolHot,
  },
  data() {
    return {
      loading: true,
      year: null,
      universityCode: null,
      universityName: null,
      universityProvince: null,
      universityWebsite: null,
      universityPhone: null,
      universityEmail: null,
      universityCharacteristic: null,
      collegeCode: null,
      majorCode: null,
      majorLearnway: null,
      majorResearchDirection: null,
      majorFirstSubject: null,
      majorSecondSubject: null,
      adjustGuid: null,
      adjustYear: null,
      adjustUniversityCode: null,
      adjustUniversityName: null,
      adjustUniversityProvince: null,
      adjustUniversityPartition: null,
      adjustCollegeCode: null,
      adjustCollegeName: null,
      adjustMajorCode: null,
      adjustMajorName: null,
      adjustMajorLearningWay: null,
      adjustMajorDegreeType: null,
      adjustMajorResearchDirection: null,
      adjustPeopleNumber: null,
      adjustDescription: null,
      documentInfos: [],
      admitInfos: [],
    };
  },
  computed: {
    schoolImg() {
      if (this.universityCode) {
        return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
      }
    },
    proxyBannerImg() {
      if (this.$store.state.proxyCode) {
        return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=6`;
      }
    },
    proxyContactImg() {
      if (this.$store.state.proxyCode) {
        return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
      }
    },
    adjustDescriptionHtml() {
      return Tools.textToHtml(this.adjustDescription);
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.year = this.$route.params.year;
      this.universityCode = this.$route.params.universityCode;
      this.collegeCode = this.$route.params.collegeCode;
      this.majorCode = this.$route.params.majorCode;
      this.majorLearnway = this.$route.params.majorLearnway;
    },
    //获取大学信息
    getUniversityInfo() {
      var model = {
        UniversityCode: this.universityCode,
      };
      return this.$axios
        .post(Config.adjust.schoolInfo, model)
        .then((res) => {
          this.universityName = res.data.Data.UniversityName;
          this.universityProvince = res.data.Data.UniversityProvince;
          this.universityWebsite = res.data.Data.UniversityWebsite;
          this.universityPhone = res.data.Data.UniversityPhone;
          this.universityEmail = res.data.Data.UniversityEmail;
          this.universityCharacteristic = res.data.Data.UniversityCharacteristic;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取专业信息
    getMajorInfo() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        MajorLearningWay: this.majorLearnway,
      };
      this.$axios
        .post(Config.adjust.majorInfo, model)
        .then((res) => {
          this.majorResearchDirection = res.data.Data.MajorResearchDirection;
          this.majorFirstSubject = res.data.Data.MajorFirstSubject;
          this.majorSecondSubject = res.data.Data.MajorSecondSubject;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取调剂信息
    getAdjustInfo() {
      this.loading = true;
      var model = {
        adjustYear: this.year,
        adjustUniversityCode: this.universityCode,
        adjustCollegeCode: this.collegeCode,
        adjustMajorCode: this.majorCode,
        adjustMajorLearningWay: this.majorLearnway,
      };
      return this.$axios
        .post(Config.adjust.info, model)
        .then((res) => {
          this.adjustYear = res.data.Data.AdjustYear;
          this.adjustUniversityCode = res.data.Data.AdjustUniversityCode;
          this.adjustUniversityName = res.data.Data.AdjustUniversityName;
          this.adjustUniversityProvince = res.data.Data.AdjustUniversityProvince;
          this.adjustUniversityPartition = res.data.Data.AdjustUniversityPartition;
          this.adjustCollegeCode = res.data.Data.AdjustCollegeCode;
          this.adjustCollegeName = res.data.Data.AdjustCollegeName;
          this.adjustMajorCode = res.data.Data.AdjustMajorCode;
          this.adjustMajorName = res.data.Data.AdjustMajorName;
          this.adjustMajorLearningWay = res.data.Data.AdjustMajorLearningWay;
          this.adjustMajorDegreeType = res.data.Data.AdjustMajorDegreeType;
          this.adjustMajorResearchDirection = res.data.Data.AdjustMajorResearchDirection;
          this.adjustPeopleNumber = res.data.Data.AdjustPeopleNumber;
          this.adjustDescription = res.data.Data.AdjustDescription;
          this.addVisitProductRecord();
          this.addVisitSchoolRecord();
          this.loading = false;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联文档列表
    getDocumentInfos() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.adjust.related.secondDocumentList, model)
        .then((res) => {
          this.documentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联报录列表
    getAdmitInfos() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.adjust.related.admitList, model)
        .then((res) => {
          this.admitInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      if (this.adjustUniversityName && this.adjustCollegeName && this.adjustMajorName) {
        Manager.addVisitProductRecord(`调剂公告 ${this.adjustUniversityName}-${this.adjustCollegeName}-${this.adjustMajorName}`);
      }
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      if (this.adjustUniversityCode && this.adjustCollegeCode && this.adjustMajorCode) {
        Manager.addVisitSchoolRecord({
          UniversityCode: this.adjustUniversityCode,
          CollegeCode: this.adjustCollegeCode,
          MajorCode: this.adjustMajorCode,
          RecordModule: 8,
        });
      }
    },
    //点击资料
    onDocumentClick(documentInfo) {
      window.open(this.$router.resolve({
        name: 'Document1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          documentGuid: documentInfo.DocumentGuid
        }
      }).href, '_blank')
    },
    //点击报录数据
    onAdmitClick(admitInfo) {
      window.open(this.$router.resolve({
        name: "Admit1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: this.universityCode,
          collegeCode: this.adjustCollegeCode,
          majorCode: this.adjustMajorCode,
          majorLearnway: this.adjustMajorLearningWay,
        }
      }).href, '_blank')
    },
  },
  mounted() {
    this.getRoutParams();
    this.getUniversityInfo();
    this.getMajorInfo();
    this.getAdjustInfo();
    this.getAdmitInfos();
    this.getDocumentInfos();
  }
};
</script>

<style scoped>
.content-box {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.content-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.content-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
}

.content-border {
  border: 1px solid #e6e6e6;
  padding: 10px 30px;
}

.content-major {
  margin: 10px 0;
}

.content-subject {
  margin: 10px 0;
}

.content-document {
  margin: 10px 0;
}

.content-major-item {
  display: inline-block;
  width: 50%;
  margin: 10px 0;
}

.content-major-item-title {
  color: #555;
  font-size: 14px;
  font-weight: 700;
}

.content-major-item-text {
  color: #555;
  font-size: 14px;
}

.content-subject-item {
  display: flex;
  margin: 20px 0;
}

.content-subject-item-title {
  min-width: 70px;
  color: #555;
  font-size: 14px;
  font-weight: 700;
  word-spacing: 22px;
}

.content-subject-item-text {
  color: #555;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.content-subject-item-name {
  flex: 1;
  margin-right: 20px;
  cursor: pointer;
  color: #555;
  font-size: 14px;
}

.content-subject-item-name:hover {
  color: #fe5e03;
}

.content-subject-item-price {
  min-width: 40px;
  color: #555;
  font-size: 14px;
}

.content-exemption {
  color: #000000a6;
  font-size: 14px;
  margin-top: 10px;
}
</style>